import React, { createContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const AuthContext = createContext(null);

export const useAuth = () => React.useContext(AuthContext);

const auth = getAuth();

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loaded, setLoaded] = useState(null);
  const [secretKey, setSecretKey] = useState(null);
  const [role, setRole] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUser(user);
      } else {
        // User is signed out
        setUser(null);
      }
      setLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (user !== null) {
      const docRef = doc(db, 'users', user.uid);
      getDoc(docRef).then((userObject) => {
        setRole(userObject.data().role);
      });
    }
  }, [user]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = { user, secretKey, setSecretKey, role };

  if (!loaded) {
    return <div>Loading...</div>;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
