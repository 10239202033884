import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Control, Controller } from 'react-hook-form';
import { Quarter } from '../models/Quarter';

type Props = {
  name: string;
  placeholder: string;
  type?: string;
  as?: 'input' | 'textarea';
  addOn?: boolean | string;
  required?: boolean;
  control: Control;
  defaultValue: any;
  errors: { [x: string]: any };
  children: React.ReactNode;
};

function InputField({
  name,
  placeholder,
  type,
  as,
  addOn,
  required,
  control,
  defaultValue,
  errors,
  children,
}: Props) {
  const controller = (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required }}
      render={({ field: { onChange, ref }, fieldState: { invalid } }) => (
        <Form.Control
          ref={ref}
          onChange={onChange}
          type={type}
          as={as}
          placeholder={placeholder}
          defaultValue={defaultValue}
          isInvalid={invalid}
        />
      )}
    />
  );

  return (
    <>
      <Form.Group className="mb-3" controlId={name}>
        <Form.Label dangerouslySetInnerHTML={{ __html: children }} />
        {!addOn ? (
          controller
        ) : (
          <InputGroup className="mb-3">
            <InputGroup.Text>{addOn}</InputGroup.Text>
            {controller}
          </InputGroup>
        )}
      </Form.Group>

      {errors[name] && <p className="text-danger">This field is required</p>}
    </>
  );
}

InputField.defaultProps = {
  type: 'text',
  as: 'input',
  addOn: false,
  required: false,
};

export default InputField;
