import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

/**
 * Generate PDF
 * Open postits are not showing when generating PDF
 */
export default function exportToPDF() {
  html2canvas(document.getElementById('app'), {
    proxy: 'https://us-central1-adp3206-whiteboard.cloudfunctions.net/html2canvas',
  }).then((canvasAsImageB64) => {
    const pdfDoc = new jsPDF({
      orientation: 'l',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
      floatPrecision: 16, // or "smart", default is 16
    });

    const widthToHeightRatio = window.document.body.clientHeight / window.document.body.clientWidth;

    const xOffset = 10;
    const yOffset = 10;
    const width = 297 - 2 * xOffset;
    const height = width * widthToHeightRatio;
    pdfDoc.addImage(canvasAsImageB64, 'PNG', xOffset, yOffset, width, height);
    pdfDoc.save(`export.pdf`);
  });
}
