import React from 'react';
import { Col, Badge } from 'react-bootstrap';
import { Control } from 'react-hook-form';
import InputField from './InputField';
import { Quarter } from '../models/Quarter';

type Props = {
  title: string;
  identifier: string;
  code?: boolean | number;
  addOn?: boolean | string;
  control: Control;
  quarter: Quarter;
  errors: { [x: string]: any };
};

function KPIInput({ control, quarter, errors, title, addOn, identifier, code }: Props) {
  return (
    <Col md={6}>
      <div className="bg-light p-2 rounded mb-3">
        {code && (
          <Badge pill bg="primary">
            No. {code}
          </Badge>
        )}
        <InputField
          name={identifier}
          placeholder="Enter percentage"
          type="number"
          addOn={addOn || '%'}
          control={control}
          defaultValue={quarter ? quarter[identifier] : null}
          errors={errors}
        >
          {title}
        </InputField>

        <InputField
          name={`${identifier}Explanation`}
          placeholder="Enter explanation"
          as="textarea"
          control={control}
          defaultValue={quarter ? quarter[`${identifier}Explanation`] : null}
          errors={errors}
        >
          Additional information and actions
        </InputField>
      </div>
    </Col>
  );
}

KPIInput.defaultProps = {
  addOn: false,
  code: false,
};

export default KPIInput;
