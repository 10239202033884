import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Modal, Alert, Row, Col } from 'react-bootstrap';
import { collection, doc, deleteDoc, setDoc } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../authentication/AuthProvider';
import { db, logout } from '../firebase';
import { Quarter, quarterConverter } from '../models/Quarter';

function Admin() {
  const auth = useAuth();
  const [showDelete, setShowDelete] = useState(false);
  const [toDelete, setToDelete] = useState('');
  const quartersRef = collection(db, auth.secretKey).withConverter(quarterConverter);
  const [quarters, loading, error, snapshot] = useCollectionData<Quarter>(quartersRef);

  const navigate = useNavigate();

  const handleDeletePrompt = (quarterId: string) => {
    setToDelete(quarterId);
    setShowDelete(true);
  };

  const handleDelete = async () => {
    await deleteDoc(doc(db, auth.secretKey, toDelete));
    setShowDelete(false);
  };

  const handleTogglePublish = (quarterId: string, publish: boolean) => {
    const quarterRef = doc(db, auth.secretKey, quarterId);
    setDoc(
      quarterRef,
      {
        published: publish,
      },
      { merge: true }
    );
  };

  if (loading) {
    return <p>loading</p>;
  }

  return (
    <Container>
      <Row>
        <Col className="p-3 px-5 bg-white text-body admin-container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Quarters</h2>
            <Button onClick={() => navigate('/quarters/add')} className="my-2">
              Add
            </Button>
          </div>
          <Table bordered>
            <thead>
              <tr>
                <th>Year</th>
                <th>Quarter</th>
              </tr>
            </thead>
            <tbody>
              {quarters
                .sort((a, b) => {
                  if (a.year !== b.year) {
                    return a.year - b.year;
                  }
                  return a.quarter - b.quarter;
                })
                .map((quarter) => (
                  <tr key={quarter.id}>
                    <td>{quarter.year}</td>
                    <td>{quarter.quarter}</td>
                    <td>
                      <Button
                        className="mx-1"
                        onClick={() => navigate(`/quarters/edit/${quarter.id}`)}
                      >
                        Edit
                      </Button>
                      {auth.role === 'admin' && (
                        <>
                          <Button
                            className="mx-1"
                            onClick={() => handleTogglePublish(quarter.id, !quarter.published)}
                            variant={quarter.published ? 'warning' : 'success'}
                          >
                            {quarter.published ? 'Unpublish' : 'Publish'}
                          </Button>
                          <Button
                            className="mx-1 text-white"
                            onClick={() => handleDeletePrompt(quarter.id)}
                            variant="danger"
                          >
                            Delete
                          </Button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete entry</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to permanently delete this entry?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDelete(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Admin;
