import React, { useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { doc, collection, addDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { useDocumentDataOnce, useCollectionData } from 'react-firebase-hooks/firestore';
import { db } from '../firebase';
import { Quarter, quarterConverter } from '../models/Quarter';
import KPIInput from './KPIInput';
import { useAuth } from '../authentication/AuthProvider';
import InputField from './InputField';
import FindingsInput from './FindingsInput';
import { KPI, KPIconverter } from '../models/KPI';

function AddEditQuarter() {
  const auth = useAuth();
  const [publish, setPublish] = useState(null);
  const navigateTo = useNavigate();
  const quartersRef = collection(db, auth.secretKey).withConverter(quarterConverter);
  const [quarters] = useCollectionData(quartersRef);
  const [error, setError] = useState(null);
  const { quarterId } = useParams();
  // This breaks if quarterId is null so just set to 1 to let it fail silently on Add form
  const quarterRef = doc(db, auth.secretKey, quarterId || '1').withConverter(quarterConverter);
  const [quarter, loading] = useDocumentDataOnce<Quarter>(quarterRef);
  const KPIref = collection(db, 'kpis').withConverter(KPIconverter);
  const [formFields] = useCollectionData<KPI>(KPIref);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const matchingQs = quarters?.filter((x) => x.year === data.year);
    const quarterData: Quarter = {
      ...data,
      updatedAt: Timestamp.fromDate(new Date()),
    };

    if (publish) {
      quarterData.published = true;
    } else if (!quarter) {
      quarterData.published = false;
    }

    if (quarter) {
      if (
        matchingQs
          .filter((x) => x.id !== quarter.id)
          .map((x) => x.quarter)
          .includes(data.quarter)
      ) {
        setError(
          `Quarter ${data.quarter} already exists for ${data.year}. Please go back and edit that quarter instead`
        );
      } else {
        updateDoc(quarterRef, quarterData).then(() => {
          sessionStorage.setItem('admin-message', 'Quarter successfully updated');
          navigateTo('/admin');
        });
      }
    } else if (matchingQs?.map((x) => x.quarter).includes(data.quarter)) {
      setError(
        `Quarter ${data.quarter} already exists for ${data.year}. Please go back and edit that quarter instead`
      );
    } else {
      quarterData.createdAt = Timestamp.fromDate(new Date());
      addDoc(collection(db, auth.secretKey), quarterData).then(() => {
        sessionStorage.setItem('admin-message', 'Quarter successfully added');
        navigateTo('/admin');
      });
    }
  };

  return loading ? (
    <p>Loading</p>
  ) : (
    <Container>
      <Row>
        <Col className="p-3 bg-white text-body admin-container">
          <fieldset>
            <legend className="text-center">Add/Edit Quarter</legend>
            <Form onSubmit={handleSubmit(onSubmit)} novalidate="novalidate">
              <Row>
                <Col>
                  {/* year */}
                  <InputField
                    name="year"
                    placeholder="Enter year"
                    type="number"
                    required
                    control={control}
                    defaultValue={quarter?.year}
                    errors={errors}
                  >
                    Year
                  </InputField>
                </Col>
                <Col>
                  {/* quarter */}
                  <InputField
                    name="quarter"
                    placeholder="Enter quarter"
                    type="number"
                    required
                    control={control}
                    defaultValue={quarter?.quarter}
                    errors={errors}
                  >
                    Quarter
                  </InputField>
                </Col>
              </Row>

              <Row>
                {formFields
                  ?.sort((a, b) => a.id - b.id)
                  .map((field) =>
                    field.identifier === 'findings' ? (
                      <FindingsInput control={control} quarter={quarter} code={9} errors={errors} />
                    ) : (
                      <KPIInput
                        control={control}
                        quarter={quarter}
                        errors={errors}
                        title={field.title}
                        addOn={field?.unit === 'month' ? 'Months' : '%'}
                        identifier={field.identifier}
                        code={field.id}
                      />
                    )
                  )}
              </Row>
              {error && <p className="text-danger text-center">{error}</p>}
              <div className="d-flex justify-content-center">
                <Button variant="secondary" className="mx-2" onClick={() => navigateTo('/admin')}>
                  Back
                </Button>

                <Button
                  variant="primary"
                  className="mx-2"
                  type="submit"
                  onClick={() => {
                    setPublish(null);
                    handleSubmit(onSubmit);
                  }}
                >
                  Save
                </Button>
                {auth.role === 'admin' &&
                  (!quarter || !quarter.published ? (
                    <Button
                      variant="success"
                      className="mx-2"
                      type="submit"
                      onClick={() => {
                        setPublish(true);
                        handleSubmit(onSubmit);
                      }}
                    >
                      Save and Publish
                    </Button>
                  ) : (
                    <Button
                      variant="warning"
                      className="mx-2"
                      type="submit"
                      onClick={() => {
                        setPublish(true);
                        handleSubmit(onSubmit);
                      }}
                    >
                      Save and Unpublish
                    </Button>
                  ))}
              </div>
            </Form>
          </fieldset>
        </Col>
      </Row>
    </Container>
  );
}

export default AddEditQuarter;
