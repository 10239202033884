import React, { useState, useEffect } from 'react';
import AdminQuarters from './AdminQuarters';
import AdminKPIs from './AdminKPIs';
import { db, logout } from '../firebase';
import { Container, Table, Button, Modal, Alert, Row, Col } from 'react-bootstrap';

function Admin() {
    const [message, setMessage] = useState(null);

  useEffect(() => {
    setMessage(sessionStorage.getItem('admin-message'));
    sessionStorage.removeItem('admin-message');
    setTimeout(() => {
      setMessage(sessionStorage.getItem('admin-message'));
    }, 5000);
  }, []);

  return (
    <>
    <Container>
      <Row>
        <Col className="p-3 px-5 bg-white text-body admin-container">
          <div className="d-flex align-items-center justify-content-between">
            <h1 className="text-center">Admin Panel</h1>
            <Button
              className="logout"
              onClick={() => {
                logout();
              }}
              >
              Logout
            </Button>
          </div>
          {message !== null && (
            <Alert key="success" variant="success" className="admin-message">
              {message}
            </Alert>
          )}
        </Col>

      </Row>
    </Container>
    <AdminQuarters />
    <AdminKPIs />
    </>
  );
}

export default Admin;
