import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import Layout from './Layout';
import AuthProvider from '../authentication/AuthProvider';
import RequireSecretKey from '../authentication/RequireSecretKey';
import RequireAuth from '../authentication/RequireAuth';
import Dashboard from './Dashboard';
import DashboardProvider from '../providers/DashboardProvider';
import AccessDenied from './AccessDenied';
import Admin from './Admin';
import Login from './Login';
import AddEditQuarter from './AddEditQuarter';
import EditKPI from './EditKPI';
import ParticleBackground from './ParticleBackground';

import Full from '../assets/images/full.png';
import Unfull from '../assets/images/unfull.png';

function App() {
  const handle = useFullScreenHandle();

  return (
    <FullScreen handle={handle}>
      <div className="fullscreen-button">
        <button className="btn" type="button" onClick={handle.active ? handle.exit : handle.enter}>
          {handle.active ? (
            <img src={Full} height="30" width="30" alt="close modal" />
          ) : (
            <img src={Unfull} height="30" width="30" alt="close modal" />
          )}
        </button>
      </div>
      <p className="bottom-text">For Ipsen internal use only</p>
      <ParticleBackground />
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<AccessDenied />} />
            <Route
              path=":key"
              element={
                <RequireSecretKey>
                  <DashboardProvider>
                    <Dashboard />
                  </DashboardProvider>
                </RequireSecretKey>
              }
            />
            <Route
              path="admin"
              element={
                <RequireAuth>
                  <Admin />
                </RequireAuth>
              }
            />
            <Route
              path="quarters/add"
              element={
                <RequireAuth>
                  <AddEditQuarter />
                </RequireAuth>
              }
            />
            <Route
              path="quarters/edit/:quarterId"
              element={
                <RequireAuth>
                  <AddEditQuarter />
                </RequireAuth>
              }
            />
            <Route
              path="kpi/edit/:kpiId"
              element={
                <RequireAuth>
                  <EditKPI />
                </RequireAuth>
              }
            />
            <Route path="login" element={<Login />} />
            <Route path="access-denied" element={<AccessDenied />} />
          </Route>
        </Routes>
      </AuthProvider>
    </FullScreen>
  );
}

export default App;
