import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  Timestamp,
} from 'firebase/firestore';

export type KPI = {
  id: number;
  identifier: string;
  title: string;
  passBoundary: number;
  failBoundary: number;
  criticalPassBoundary: number;
  criticalFailBoundary: number;
  majorPassBoundary: number;
  majorFailBoundary: number;
  inverse: boolean;
  unit: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  hide: boolean;
  showPass: boolean;
};

export const KPIconverter: FirestoreDataConverter<KPI> = {
  toFirestore(data: WithFieldValue<KPI>): DocumentData {
    return {
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      code: data.id,
      id: data.identifier,
      title: data.title,
      passBoundary: data.passBoundary,
      failBoundary: data.failBoundary,
      criticalPassBoundary: data.criticalPassBoundary,
      criticalFailBoundary: data.criticalFailBoundary,
      majorPassBoundary: data.majorPassBoundary,
      majorFailBoundary: data.majorFailBoundary,
      inverse: data.inverse,
      unit: data.unit,
      hide: data.hide,
      showPass: data.showPass,
    };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): KPI {
    const data = snapshot.data(options);
    return {
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      id: data.code,
      identifier: data.id,
      title: data.title,
      passBoundary: data.passBoundary,
      failBoundary: data.failBoundary,
      criticalPassBoundary: data.criticalPassBoundary,
      criticalFailBoundary: data.criticalFailBoundary,
      majorPassBoundary: data.majorPassBoundary,
      majorFailBoundary: data.majorFailBoundary,
      inverse: data.inverse,
      unit: data.unit,
      hide: data.hide,
      showPass: data.showPass,
    };
  },
};
