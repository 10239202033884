import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { doc, collection, addDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { useDocumentDataOnce, useCollectionData } from 'react-firebase-hooks/firestore';
import { db } from '../firebase';
import KPIInput from './KPIInput';
import { useAuth } from '../authentication/AuthProvider';
import { Control, Controller } from 'react-hook-form';
import InputField from './InputField';
import FindingsInput from './FindingsInput';
import { KPI, KPIconverter } from '../models/KPI';

function EditKPI() {
  const auth = useAuth();
  const [publish, setPublish] = useState(null);
  const navigateTo = useNavigate();
  const [inverse, setInverse] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [error, setError] = useState(null);
  const { kpiId } = useParams();
  const kpiRef = doc(db, 'kpis', kpiId).withConverter(KPIconverter);
  const [KPI, loading] = useDocumentDataOnce<KPI>(kpiRef);
  const KPIref = collection(db, 'kpis').withConverter(KPIconverter);
  const [formFields] = useCollectionData<KPI>(KPIref);

  useEffect(() => {
    setInverse(!!KPI?.inverse)
    setShowPass(!!KPI?.showPass)
  }, [KPI])

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const submitArray = Object.entries(data);
    const submitData = Object.fromEntries(submitArray.filter((x) => !x.includes(undefined)))
    submitData.inverse = !!inverse;
    submitData.showPass = !!showPass;

    updateDoc(kpiRef, submitData).then(() => {
      sessionStorage.setItem('admin-message', 'KPI successfully updated');
      navigateTo('/admin');
    });
  };

  return loading ? (
    <p>Loading</p>
  ) : (
    <Container>
      <Row>
        <Col className="p-3 bg-white text-body admin-container">
          <fieldset>
            <legend className="text-center">Add/Edit KPI</legend>
            <Form onSubmit={handleSubmit(onSubmit)} novalidate="novalidate">
              <Row>
                <Col sm={12}>
                  {/* year */}
                  <InputField
                    name="title"
                    placeholder="Enter title"
                    type="text"
                    required
                    control={control}
                    defaultValue={KPI.title}
                    errors={errors}
                  >
                    Title
                  </InputField>
                </Col>
                <hr />
                <Col sm={6}>
                  {/* quarter */}
                  <InputField
                    name="passBoundary"
                    placeholder="Enter pass boundary"
                    type="number"
                    control={control}
                    defaultValue={KPI.passBoundary}
                    errors={errors}
                  >
                    Pass Boundary
                  </InputField>
                </Col>
                <Col sm={6}>
                  {/* quarter */}
                  <InputField
                    name="failBoundary"
                    placeholder="Enter fail boundary"
                    type="number"
                    control={control}
                    defaultValue={KPI.failBoundary}
                    errors={errors}
                  >
                    Fail Boundary
                  </InputField>
                </Col>
                <Col sm={6}>
                  {/* quarter */}
                  <InputField
                    name="majorPassBoundary"
                    placeholder="Enter major pass boundary"
                    type="number"
                    control={control}
                    defaultValue={KPI.majorPassBoundary}
                    errors={errors}
                  >
                    Major Pass Boundary
                  </InputField>
                </Col>
                <Col sm={6}>
                  {/* quarter */}
                  <InputField
                    name="majorFailBoundary"
                    placeholder="Enter major fail boundary"
                    type="number"
                    control={control}
                    defaultValue={KPI.majorFailBoundary}
                    errors={errors}
                  >
                    Major Fail Boundary
                  </InputField>
                </Col>
                <Col sm={6}>
                  {/* quarter */}
                  <InputField
                    name="criticalPassBoundary"
                    placeholder="Enter critical pass boundary"
                    type="number"
                    control={control}
                    defaultValue={KPI.criticalPassBoundary}
                    errors={errors}
                  >
                    Critical Pass Boundary
                  </InputField>
                </Col>
                <Col sm={6}>
                  {/* quarter */}
                  <InputField
                    name="criticalFailBoundary"
                    placeholder="Enter critical fail boundary"
                    type="number"
                    control={control}
                    defaultValue={KPI.criticalFailBoundary}
                    errors={errors}
                  >
                    Critical Fail Boundary
                  </InputField>
                </Col>
                <hr />
                <Col sm={{span: 6, offset: 3}} >
                  {/* quarter */}
                  <InputField
                    name="unit"
                    placeholder="Enter unit"
                    type="text"
                    control={control}
                    defaultValue={KPI.unit}
                    errors={errors}
                  >
                    Unit
                  </InputField>
                </Col>
                <Col sm={6} className="d-flex align-items-center justify-content-center pb-3">
                  {/* quarter */}

                  <Form.Check
                    type='checkbox'
                    id='inverse'
                    label='Inverse'
                    checked={inverse}
                    onChange={(e) => { setInverse(e.target.checked);}}
                  />


                </Col>
                <Col sm={6} className="d-flex align-items-center justify-content-center pb-3">
                  {/* quarter */}

                  <Form.Check
                    type='checkbox'
                    id='showPass'
                    label='Show the word "passed"'
                    checked={showPass}
                    onChange={(e) => { setShowPass(e.target.checked);}}
                  />


                </Col>

              </Row>


              {error && <p className="text-danger text-center">{error}</p>}
              <div className="d-flex justify-content-center">
                <Button variant="secondary" className="mx-2" onClick={() => navigateTo('/admin')}>
                  Back
                </Button>

                <Button
                  variant="primary"
                  className="mx-2"
                  type="submit"
                  onClick={() => {
                    handleSubmit(onSubmit);
                  }}
                >
                  Save
                </Button>
              </div>
            </Form>
          </fieldset>
        </Col>
      </Row>
    </Container>
  );
}

export default EditKPI;
