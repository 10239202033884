import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';

type Props = {
  children: React.ReactNode;
};

const DashboardContext = createContext(null);

export const useDashboard = () => useContext(DashboardContext);

function DashboardProvider({ children }: Props) {
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [KPIs, setKPIs]= useState([]);
  const value = useMemo(() => ({ openInfoModal, setOpenInfoModal, KPIs, setKPIs }), [openInfoModal, KPIs]);

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
}

export default DashboardProvider;
