import React from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import { Control } from 'react-hook-form';
import { Quarter } from '../models/Quarter';
import InputField from './InputField';

type Props = {
  control: Control;
  quarter: Quarter;
  code?: boolean | number;
  errors: { [x: string]: any };
};

function FindingsInput({ control, quarter, code, errors }: Props) {
  return (
    <Col md={6}>
      <div className="bg-light p-2 rounded mb-3">
        {code && (
          <Badge pill bg="primary">
            No. {code}
          </Badge>
        )}
        <p>Critical and major findings related to Ipsen PV core activities (any HA)</p>
        <Row>
          <Col sm={6}>
            <InputField
              name="criticalFindings"
              placeholder="Enter Number of Critical Findings"
              type="number"
              control={control}
              defaultValue={quarter ? quarter['criticalFindings'] : null}
              errors={errors}
            >
              Critical
            </InputField>
          </Col>
          <Col sm={6}>
            <InputField
              name="majorFindings"
              placeholder="Enter Number of Major Findings"
              type="number"
              control={control}
              defaultValue={quarter ? quarter['majorFindings'] : null}
              errors={errors}
            >
              Major
            </InputField>
          </Col>
        </Row>

        <InputField
          name="criticalFindingsExplanation"
          placeholder="Enter explanation"
          as="textarea"
          control={control}
          defaultValue={quarter ? quarter['criticalFindingsExplanation'] : null}
          errors={errors}
        >
          Additional information and actions
        </InputField>
      </div>
    </Col>
  );
}

FindingsInput.defaultProps = {
  code: false,
};

export default FindingsInput;
