/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';

type Props = {
  years: any;
  year: string;
  setYear: any;
  open: boolean;
  setOpen: any;
};

function useOutsideAlerter(ref, setOpen) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

function YearDropdown({ years, year, setYear, open, setOpen }: Props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setOpen);

  function handleUpdateYear(y) {
    setYear(y);
  }

  return (
    <div className="year-dropdown" ref={wrapperRef}>
      <Button
        variant="secondary"
        id="dropdown-basic"
        onClick={() => {
          setOpen(!open);
        }}
      >
        {year}
      </Button>

      <div className={open ? 'years-list' : 'd-none'}>
        {years?.map((x) => (
          <div
            key={x}
            role="button"
            className={`${year === x && 'active'}`}
            onClick={() => handleUpdateYear(x)}
            onKeyDown={() => handleUpdateYear(x)}
          >
            {x}
          </div>
        ))}
      </div>
    </div>
  );
}

export default YearDropdown;
