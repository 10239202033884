import React from 'react';

function AccessDenied() {
  return (
    <div className="loader">
      <h1 className="mt-3">Access denied</h1>
    </div>
  );
}

export default AccessDenied;
