import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { useLocation, Navigate } from 'react-router-dom';
import { auth } from '../firebase';

function Login() {
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signInWithEmailAndPassword, user, loading, error] = useSignInWithEmailAndPassword(auth);

  if (error) {
    return (
      <div>
        <p>Error: {error.message}</p>
      </div>
    );
  }

  if (loading) {
    return <p>Loading...</p>;
  }

  if (user) {
    // Need to check if state is present
    return <Navigate to={location.state.from.pathname} replace />;
  }

  return (
    <Container>
      <Row>
        <Col md={{ span: 6, offset: 3 }} className="p-3 bg-white text-body admin-container">
          <fieldset>
            <legend>
              <h1>Login</h1>
            </legend>

            <Form
              onSubmit={(event) => {
                event.preventDefault();
                signInWithEmailAndPassword(email, password);
              }}
            >
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <div className="d-flex justify-content-end">
                <Button variant="primary" type="submit">
                  Login
                </Button>
              </div>
            </Form>
          </fieldset>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
