import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  Timestamp,
} from 'firebase/firestore';

export type Quarter = {
  id: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  year: number;
  quarter: number;
  certificationMSLs: number;
  certificationMSLsExplanation: string;
  targetCountryTA: number;
  targetCountryTAExplanation: string;
  publicationReach: number;
  publicationReachExplanation: string;
  timeRespondFirst: number;
  timeRespondFirstExplanation: string;
  increaseMIPost: number;
  increaseMIPostExplanation: string;
  talentRetentionRate: number;
  talentRetentionRateExplanation: string;
  talentRetentionRateLocal: number;
  talentRetentionRateLocalExplanation: string;
  safetyCompliance: number;
  safetyComplianceExplanation: string;
  criticalFindings: number;
  majorFindings: number;
  criticalFindingsExplanation: string;
  timeMARB: number;
  timeMARBExplanation: string;
  recruitmentCSS: number;
  recruitmentCSSExplanation: string;
  published: boolean;
};

export const quarterConverter: FirestoreDataConverter<Quarter> = {
  toFirestore(data: WithFieldValue<Quarter>): DocumentData {
    return {
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      year: data.year,
      quarter: data.quarter,
      certificationMSLs: data.certificationMSLs,
      certificationMSLsExplanation: data.certificationMSLsExplanation,
      targetCountryTA: data.targetCountryTA,
      targetCountryTAExplanation: data.targetCountryTAExplanation,
      publicationReach: data.publicationReach,
      publicationReachExplanation: data.publicationReachExplanation,
      timeRespondFirst: data.timeRespondFirst,
      timeRespondFirstExplanation: data.timeRespondFirstExplanation,
      increaseMIPost: data.increaseMIPost,
      increaseMIPostExplanation: data.increaseMIPostExplanation,
      talentRetentionRate: data.talentRetentionRate,
      talentRetentionRateExplanation: data.talentRetentionRateExplanation,
      talentRetentionRateLocal: data.talentRetentionRateLocal,
      talentRetentionRateLocalExplanation: data.talentRetentionRateLocalExplanation,
      safetyCompliance: data.safetyCompliance,
      safetyComplianceExplanation: data.safetyComplianceExplanation,
      criticalFindings: data.criticalFindings,
      majorFindings: data.majorFindings,
      criticalFindingsExplanation: data.criticalFindingsExplanation,
      timeMARB: data.timeMARB,
      timeMARBExplanation: data.timeMARBExplanation,
      recruitmentCSS: data.recruitmentCSS,
      recruitmentCSSExplanation: data.recruitmentCSSExplanation,
      published: data.published ? data.published : false,
    };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Quarter {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      year: data.year,
      quarter: data.quarter,
      certificationMSLs: data.certificationMSLs,
      certificationMSLsExplanation: data.certificationMSLsExplanation,
      targetCountryTA: data.targetCountryTA,
      targetCountryTAExplanation: data.targetCountryTAExplanation,
      publicationReach: data.publicationReach,
      publicationReachExplanation: data.publicationReachExplanation,
      timeRespondFirst: data.timeRespondFirst,
      timeRespondFirstExplanation: data.timeRespondFirstExplanation,
      increaseMIPost: data.increaseMIPost,
      increaseMIPostExplanation: data.increaseMIPostExplanation,
      talentRetentionRate: data.talentRetentionRate,
      talentRetentionRateExplanation: data.talentRetentionRateExplanation,
      talentRetentionRateLocal: data.talentRetentionRateLocal,
      talentRetentionRateLocalExplanation: data.talentRetentionRateLocalExplanation,
      safetyCompliance: data.safetyCompliance,
      safetyComplianceExplanation: data.safetyComplianceExplanation,
      criticalFindings: data.criticalFindings,
      majorFindings: data.majorFindings,
      criticalFindingsExplanation: data.criticalFindingsExplanation,
      timeMARB: data.timeMARB,
      timeMARBExplanation: data.timeMARBExplanation,
      recruitmentCSS: data.recruitmentCSS,
      recruitmentCSSExplanation: data.recruitmentCSSExplanation,
      published: data.published,
    };
  },
};
