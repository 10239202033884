import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Modal, Alert, Row, Col } from 'react-bootstrap';
import { collection } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../authentication/AuthProvider';
import { db, logout } from '../firebase';
import { KPI, KPIconverter } from '../models/KPI';

function Admin() {
  const auth = useAuth();
  const KPIref = collection(db, 'kpis').withConverter(KPIconverter);
  const [KPIs, loading, error, snapshot] = useCollectionData<KPI>(KPIref);
  const navigate = useNavigate();


  if (loading) {
    return <p>loading</p>;
  }

  return (
    <Container>
      <Row>
        <Col className="p-3 px-5 bg-white text-body admin-container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>KPIs</h2>
          </div>
          <Table bordered>
            <thead>
              <tr>
                <th>Title</th>
                <th>Units</th>
                <th>Inverse</th>
                <th>Boundaries</th>
              </tr>
            </thead>
            <tbody>
              {KPIs?.map((KPI) => (
                  <tr key={KPI.id}>
                    <td>{KPI.title}</td>
                    <td>{KPI.unit}</td>
                    <td className="d-flex justify-content-center align-items-center">{KPI.inverse ? <span>&#10003;</span> : ''}</td>
                    <td>
                      <ul>
                        {KPI.passBoundary && (
                          <li>
                            Pass: {KPI.passBoundary}
                          </li>
                        )}
                        {KPI.failBoundary && (
                          <li>
                            Fail: {KPI.failBoundary}
                          </li>
                        )}
                        {KPI.majorPassBoundary !== undefined && (
                          <li>
                            Major Pass: {KPI.majorPassBoundary}
                          </li>
                        )}
                        {KPI.majorFailBoundary !== undefined && (
                          <li>
                            Major Fail Boundary: {KPI.majorFailBoundary}
                          </li>
                        )}
                        {KPI.criticalPassBoundary !== undefined && (
                          <li>
                            Critical Pass Boundary: {KPI.criticalPassBoundary}
                          </li>
                        )}
                        {KPI.criticalFailBoundary !== undefined && (
                          <li>
                            Critical Fail Boundary: {KPI.criticalFailBoundary}
                          </li>
                        )}
                      </ul>
                    </td>
                    <td>
                      {auth.role === 'admin' && (

                        <Button
                        className="mx-1"
                        onClick={() => navigate(`/kpi/edit/${KPI.identifier}`)}
                        >
                          Edit
                        </Button>

                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default Admin;
