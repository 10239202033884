import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getAuth, signOut } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyD-HeFD7TXWDsdDs2pu_6DUQ7n03AlaWgc',
  authDomain: 'adp3474---tcme-dashboard-2022.firebaseapp.com',
  projectId: 'adp3474---tcme-dashboard-2022',
  storageBucket: 'adp3474---tcme-dashboard-2022.appspot.com',
  messagingSenderId: '811803673906',
  appId: '1:811803673906:web:e2218fa1159ea1db46832b',
  measurementId: 'G-ZN56DDKPCE',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Analytics
export const analytics = getAnalytics(app);

// Initialize Firestore
export const db = getFirestore(app);

// Initialize Auth
export const auth = getAuth();

export async function logout() {
  signOut(auth)
    .then(() => {
      window.location.reload();
    })
    .catch((error) => {
      console.error(error);
    });
}

export const functions = getFunctions(app);
