/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { Row, Col, ButtonToolbar, ButtonGroup, Button } from 'react-bootstrap';
import { collection, query, where } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import logo from '../assets/images/logo-ipsen.png';
import { quarterConverter } from '../models/Quarter';
import { db } from '../firebase';
import YearDropdown from './YearDropdown';
import KPICircles from './KPICircles';
import KPIGraphs from './KPIGraphs';
import KPIModal from './KPIModal';
import { useDashboard } from '../providers/DashboardProvider';
import { useAuth } from '../authentication/AuthProvider';
import exportToPDF from '../exportToPDF';
import { KPI, KPIconverter } from '../models/KPI';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function getYears(q) {
  return q.map((x) => x.year).filter(onlyUnique);
}

function Dashboard() {
  const auth = useAuth();
  const quartersRef = collection(db, auth.secretKey).withConverter(quarterConverter);
  const [quarters, loading, error] = useCollectionData(
    query(quartersRef, where('published', '==', true))
  );
  const [quarter, setQuarter] = useState(undefined);
  const [currentQuarters, setCurrentQuarters] = useState(undefined);
  const [year, setYear] = useState(undefined);
  const [years, setYears] = useState(undefined);
  const [yearSelectOpen, setYearSelectOpen] = useState(false);
  const KPIref = collection(db, 'kpis').withConverter(KPIconverter);
  const [formFields] = useCollectionData<KPI>(KPIref);
  const dashboard = useDashboard();

  useEffect(() => {
    if (formFields) {
      dashboard.setKPIs(formFields);
    }
  }, [formFields]);


  const [view, setView] = useState('full');

  function handleChangeQuarter(q) {
    setQuarter(q);
  }

  useEffect(() => {
    if (quarters !== undefined) {
      const yearsList = getYears(quarters);
      setYears(yearsList);
      if (yearsList.length > 0) {
        setYear(yearsList[0]);
      }
    }
  }, [quarters]);

  useEffect(() => {
    const thisYearQuarters = quarters?.filter((x) => x.year === year);
    if (thisYearQuarters?.length > 0) {
      setCurrentQuarters(thisYearQuarters);
      setQuarter(thisYearQuarters[0]);
    }
    setYearSelectOpen(false);
  }, [year]);

  return (
    <>
      <div className="header-container">
        <header className="dashboard-logo">
          <img src={logo} alt="Ipsen Logo" width="189" height="55" />
          <ButtonToolbar
            className="tabs flex-column flex-sm-row"
            aria-label="Toolbar with button groups"
          >
            <ButtonGroup className="me-sm-2" aria-label="First group">
              <Button
                variant="outline-light"
                className={`tab ${view === 'full' ? 'active' : ''}`}
                onClick={() => {
                  setView('full');
                }}
              >
                FULL
              </Button>
              <Button
                variant="outline-light"
                className={`tab ${view === 'kpi' ? 'active' : ''}`}
                onClick={() => {
                  setView('kpi');
                }}
              >
                METRICS
              </Button>
              <Button
                variant="outline-light"
                className={`tab ${view === 'graphs' ? 'active' : ''}`}
                onClick={() => {
                  setView('graphs');
                }}
              >
                GRAPHS
              </Button>
            </ButtonGroup>
            <ButtonGroup className="mt-2 mt-sm-0" aria-label="Second group">
              <Button variant="outline-light" onClick={() => exportToPDF()}>
                EXPORT
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </header>
      </div>
      <Row className={`view-${view}`}>
        {(view === 'full' || view === 'kpi') && (
          <Col xl={view === 'kpi' ? 12 : 8} className="kpi-section">
            <ul className="circle-container position-relative">
              <div className="circle-center">
                <h2>
                  CMO Metrics for Q{quarter?.quarter} {year}
                </h2>
                <p>Please click on the individual metrics for further information and actions</p>
                <YearDropdown
                  year={year}
                  years={years}
                  setYear={setYear}
                  open={yearSelectOpen}
                  setOpen={setYearSelectOpen}
                />
                <p>Choose another quarter:</p>
                <div className="quarter-switch">
                  {currentQuarters
                    ?.filter((x: any) => x.year === year)
                    .sort((a, b) => parseInt(a.quarter, 10) - parseInt(b.quarter, 10))
                    .map((x: any) => (
                      <div
                        key={x.quarter}
                        className={`quarter-button ${x.id === quarter.id && 'active'}`}
                        onClick={() => handleChangeQuarter(x)}
                        onKeyDown={() => handleChangeQuarter(x)}
                        role="button"
                        tabIndex={0}
                      >
                        {x.quarter}
                      </div>
                    ))}
                </div>
              </div>
              <KPICircles KPIs={dashboard.KPIs} quarter={quarter} />
            </ul>
          </Col>
        )}
        {(view === 'full' || view === 'graphs') && (
          <Col
            xl={view === 'graphs' ? 12 : 4}
            className={`graph-section ${view === 'graphs' && 'full-size'}`}
          >
            <div className="graph-section__wrap">
              <h2 className="text-center fs-3 fw-bold">Comparison by Quarter</h2>
              <KPIGraphs KPIs={dashboard.KPIs} quarters={quarters} fullSize={view === 'graphs'} />
            </div>
          </Col>
        )}
        {dashboard.openInfoModal && (
          <KPIModal
            KPIs={dashboard.KPIs}
            selected={dashboard.openInfoModal}
            close={dashboard.setOpenInfoModal}
            quarter={quarter}
          />
        )}
      </Row>
    </>
  );
}

export default Dashboard;
