import React from 'react';
import questionMarkIcon from '../assets/images/circle-question-light.svg';
import { useDashboard } from '../providers/DashboardProvider';

type Props = {
  id: number;
  percentage: number;
  theme?: 'success | warning | danger | secondary';
  children?: React.ReactNode;
  findings?: { critical: number; major: number };
  unit: string;
};

function CircleStat({ id, percentage, theme, children, findings, unit }: Props) {
  const dashboard = useDashboard();

  return (
    <aside className="circle-stat__wrap">
      <div className="circle-stat__inner-wrap">
        <svg viewBox="0 0 36 36" className={`circle-stat circle-stat--${theme}`}>
          <path
            className="circle-stat__bg"
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className={`circle-stat__circle circle-stat__circle--${theme}`}
            strokeDasharray={`${percentage}, 100`}
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          {!findings ? (
            <text
              x="18"
              y="20.35"
              className={`circle-stat__percentage circle-stat__percentage--${theme} circle-stat__percentage--${unit.trim()}`}
            >
              {percentage && `${percentage}${unit}`}
            </text>
          ) : (
            <>
              <text
                x="18"
                y="15"
                className={`circle-stat__findings circle-stat__findings--${theme}`}
              >
                {findings.critical} critical
              </text>
              <text
                x="18"
                y="21"
                className={`circle-stat__findings circle-stat__findings--${theme}`}
              >
                {findings.major} major
              </text>
            </>
          )}
        </svg>
        <button
          type="button"
          className="info-btn p-0 bg-transparent border-0 position-absolute"
          onClick={() => dashboard.setOpenInfoModal(id)}
        >
          <img src={questionMarkIcon} alt="Question mark" width={28} height={28} />
        </button>
      </div>
      <div className="text-info text-center">{children}</div>
    </aside>
  );
}

CircleStat.defaultProps = {
  theme: 'success',
  children: '',
  findings: false,
};

export default CircleStat;
