import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { KPI } from '../models/KPI';
import { Quarter } from '../models/Quarter';
import LineChart from './LineChart';

type Props = {
  KPIs: KPI[];
  quarters: Quarter[];
  fullSize: Boolean;
};

function KPIGraphs({ KPIs, quarters, fullSize }: Props) {
  let formattedQuarters = quarters?.sort((a, b) => a.year - b.year || a.quarter - b.quarter);
  if (formattedQuarters?.length > 4) {
    formattedQuarters = quarters?.slice(quarters.length - 4, quarters.length);
  }

  //Specific order that is different to circles due to offset
  const order = [1, 2, 3, 4, 5, 10, 8, 9, 6, 11];
  const orderedKPIS = KPIs.length > 0 ? order.map((x) => KPIs.find((f) => x === f.id)) : [];

  return (
    <div>
      <div>
        <Row>
          <Col lg={fullSize ? 6 : 12}>
            <Row className="dashboard-graph graph-legend-container">
              <Col md={{ span: 7, offset: 5 }} className="graph-legend d-flex">
                <Row className="w-100">
                  {formattedQuarters?.map((x: Quarter) => (
                    <Col key={x.id} xs={3}>
                      <div className="graph-legend-item">
                        {x.year}, Q{x.quarter}
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Col>
          {fullSize && (
            <Col lg={fullSize ? 6 : 12} className="d-none d-lg-block">
              <Row className="dashboard-graph graph-legend-container">
                <Col md={{ span: 7, offset: 5 }} className="graph-legend d-flex">
                  <Row className="w-100">
                    {formattedQuarters?.map((x: Quarter) => (
                      <Col key={x.id} xs={3}>
                        <div className="graph-legend-item">
                          {x.year}, Q{x.quarter}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>
      <Row>
        {orderedKPIS?.map((kpi: KPI) => (
          <Col key={kpi.id} lg={fullSize ? 6 : 12}>
            <figure className="graph-section__item mb-0">
              <LineChart kpi={kpi} quarters={formattedQuarters} />
            </figure>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default KPIGraphs;
