import React from 'react';

function AccessDenied() {
  return (
    <div className="loader">
      <div className="lds-ring mt-3">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default AccessDenied;
