import React, { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAuth } from './AuthProvider';
import Loader from '../components/Loader';

const functions = getFunctions();
const getSecretKey = httpsCallable(functions, 'getSecretKey');

function RequireAuth({ children }) {
  const auth = useAuth();
  const location = useLocation();
  const [key, setKey] = useState(auth.secretKey);
  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  useEffect(() => {
    auth.setSecretKey(key);
    getSecretKey({ user: auth.user.uid }).then((secretKey) => {
      setKey(secretKey.data);
      auth.setSecretKey(secretKey.data);
    });
  }, []);

  if (key === null) {
    return <Loader />;
  }

  return children;
}

export default RequireAuth;
