import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAuth } from './AuthProvider';

const functions = getFunctions();
const checkSecretKey = httpsCallable(functions, 'checkSecretKey');

function RequireSecretKey({ children }) {
  // Need to retrieve the key server side somehow
  const { key } = useParams();
  const auth = useAuth();
  const [keyValid, setKeyValid] = useState(null);

  useEffect(() => {
    auth.setSecretKey(key);
    checkSecretKey({ code: key }).then((result) => setKeyValid(result.data));
  }, []);

  if (keyValid === null) {
    return (
      <div className="loader">
        <div className="lds-ring mt-3">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }

  if (!keyValid) {
    // Set this in context to be used for db calls
    return <Navigate to="/access-denied" replace />;
  }

  return children;
}

export default RequireSecretKey;
