import React from 'react';
import { Row, Col } from 'react-bootstrap';
import KPI from '../models/KPI';
import { Quarter } from '../models/Quarter';
import Cross from '../assets/images/cross.png';

type Props = {
  KPIs: KPI[];
  selected: number;
  close: (id: boolean) => void;
  quarter: Quarter;
};

function KPIModal({ KPIs, selected, close, quarter }: Props) {
  const kpi: KPI = KPIs.find((kpi) => kpi.id === selected);
  let kpiCopy = kpi.identifier && quarter ? quarter[`${kpi.identifier}Explanation`] : false;

  if (kpi.identifier === 'findings') {
    kpiCopy = kpi.identifier && quarter ? quarter.criticalFindingsExplanation : false;
  }

  const pass = kpi.identifier === 'findings' ? 0 : kpi.passBoundary;
  const fail = kpi.identifier === 'findings' ? 0 : kpi.failBoundary;
  const unit = kpi.unit !== undefined ? ` ${kpi.unit}${pass !== '1' && 's'}` : '%';

  const successText = kpi.identifier === 'findings' ? 'No critical & No major' : `${kpi.inverse ? '<' : '>'}${pass}${unit} ${kpi.showPass ? 'passed' : ''}`;
  const warningText =
    kpi.identifier === 'findings' ? 'No critical & 1 or 2 major' : `${kpi.inverse ? `${pass}-${fail}` : `${fail}-${pass}`}${unit} ${kpi.showPass ? 'passed' : ''}`;
  const dangerText =
    kpi.identifier === 'findings' ? 'At least 1 critical or 3 major' : `${kpi.inverse ? '' : '<'}${fail}${unit}${kpi.inverse ? '+' : ''} ${kpi.showPass ? 'passed' : ''}`;

  const handleClose = () => close(false);

  return (
    <>
      <div className="info-modal__backdrop" onClick={handleClose}>
        <div className="info-modal__inner" />
      </div>
      <div className="info-modal-container">
        <aside className="info-modal">
          <div className="info-modal__close" onClick={handleClose}>
            <img src={Cross} height="15" width="15" alt="close modal" />
          </div>
          <h3 className="info-modal__title">{kpi.title}</h3>
          {kpiCopy && <p>{kpiCopy}</p>}
          <Row>
            <Col className="d-flex flex-row align-items-center">
              <div className="info-modal__circle border border-3 border-success" />
              <span className="info-modal__circle-copy ps-2">{successText}</span>
            </Col>
            <Col className="d-flex flex-row align-items-center">
              <div className="info-modal__circle border border-3 border-warning" />
              <span className="info-modal__circle-copy ps-2">{warningText}</span>
            </Col>
            <Col className="d-flex flex-row align-items-center">
              <div className="info-modal__circle border border-3 border-danger" />
              <span className="info-modal__circle-copy ps-2">{dangerText}</span>
            </Col>
          </Row>
        </aside>
      </div>
    </>
  );
}

export default KPIModal;
