import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import KPI from '../models/kpi';
import { Quarter } from '../models/Quarter';

type Props = {
  kpi: any;
  quarters: any;
};

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const disabledColour = '#6c757d';
const successColour = '#64FB5A';
const warningColour = '#F6BF07';
const dangerColour = '#FC3E5E';

function setBackgroundColor(value, kpi: KPI) {
  if (value === undefined) {
    return disabledColour;
  }

  if (
    (kpi.inverse && Number(value) < Number(kpi.passBoundary)) ||
    (!kpi.inverse && Number(value) > Number(kpi.passBoundary))
  ) {
    return successColour;
  }
  if (
    (kpi.inverse && Number(value) <= Number(kpi.failBoundary)) ||
    (!kpi.inverse && Number(value) >= Number(kpi.failBoundary))
  ) {
    return warningColour;
  }

  return dangerColour;
}

function setBackgroundColorFindings(value) {
  if (value === undefined) {
    return disabledColour;
  }

  if (value === 0) {
    return successColour;
  }

  if (value === 1) {
    return warningColour;
  }

  return dangerColour;
}

function setMaxValue(values: number[], kpi: KPI) {
  if (values === undefined) {
    return 0;
  }
  let maxValue = Math.max(...values.filter((x) => x !== undefined));

  if (kpi.identifier !== 'findings') {
    maxValue = maxValue > 100 ? maxValue : 100;
  } else {
    maxValue = maxValue > 10 ? maxValue : 10;
  }

  return maxValue;
}

function LineChart({ kpi, quarters }: Props) {
  let values: number[];
  if (kpi.identifier === 'findings') {
    values = quarters?.map((quarter: Quarter) => {
      let kpiItemMajor = quarter?.majorFindings;
      let kpiItemCritical = quarter?.criticalFindings;
      if (kpiItemMajor === '' || kpiItemMajor === null) {
        kpiItemMajor = undefined;
      } else {
        kpiItemMajor = parseInt(kpiItemMajor, 10);
      }
      if (kpiItemCritical === '' || kpiItemCritical === null) {
        kpiItemCritical = undefined;
      } else {
        kpiItemCritical = parseInt(kpiItemCritical, 10);
      }

      // Both empty
      if (kpiItemMajor === undefined && kpiItemCritical === undefined) {
        return undefined;
      }

      if (kpiItemMajor === undefined) {
        return kpiItemCritical;
      }

      if (kpiItemCritical === undefined) {
        return kpiItemMajor;
      }

      return kpiItemMajor + kpiItemCritical;
    });
  } else {
    values = quarters?.map((quarter: Quarter) => {
      let kpiItem = quarter?.[kpi.identifier];
      if (kpiItem === '' || kpiItem === null) {
        kpiItem = undefined;
      } else {
        kpiItem = parseInt(kpiItem, 10);
      }
      return kpiItem;
    });
  }

  const maxValue = setMaxValue(values, kpi);
  return values ? (
    <Row className="dashboard-graph">
      <Col md={5} className="d-flex align-items-center">
        <p className="dashboard-graph__text mb-0">{kpi.title}</p>
      </Col>
      <Col md={7} className="dashboard-graph__item">
        <Line
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            layout: {
              autoPadding: true,
            },
            scales: {
              x: {
                grid: {
                  color: 'rgba(255, 255, 255, 0.3)',
                  drawBorder: false,
                },
                ticks: {
                  display: false,
                },
              },
              y: {
                min: 0,
                max: maxValue,
                grid: {
                  display: false,
                },
                ticks: {
                  display: false,
                },
              },
            },
          }}
          data={{
            labels: ['Q1', 'Q2', 'Q3', 'Q4'],
            datasets: [
              {
                label: 'Dataset',
                data: values?.map((x) => (x === undefined ? 0 : x)),
                backgroundColor: 'rgb(19, 54, 109)',
                borderColor: '#05A6D9',
                borderWidth: 2,
                pointBorderColor: values?.map((x) =>
                  kpi.identifier === 'findings'
                    ? setBackgroundColorFindings(x)
                    : setBackgroundColor(x, kpi),
                ),
                pointBorderWidth: 3,
                pointStyle: 'circle',
                pointRadius: 10,
                pointHoverRadius: 15,
                clip: false,
              },
            ],
          }}
        />
      </Col>
    </Row>
  ) : (
    <div>...</div>
  );
}

export default LineChart;
