import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './firebase';
import App from './components/App';
// import ParticleBackground from './components/ParticleBackground';

const container = document.getElementById('app');

const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
    {/* <ParticleBackground /> */}
    <App />
  </BrowserRouter>
);
