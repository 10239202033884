import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

function Layout() {
  return (
    <Container fluid className="h-100 text-white app-bg">
      <Outlet />
    </Container>
  );
}

export default Layout;
