/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import CircleStat from './CircleStat';
import KPI from '../models/KPI';
import { Quarter } from '../models/Quarter';

type Props = {
  KPIs: KPI[];
  quarter: Quarter;
};

function getTheme(value, kpi: KPI) {
  if (
    (kpi.inverse && Number(value) < Number(kpi.passBoundary)) ||
    (!kpi.inverse && Number(value) > Number(kpi.passBoundary))
  ) {
    return 'success';
  }
  if (
    (kpi.inverse && Number(value) <= Number(kpi.failBoundary)) ||
    (!kpi.inverse && Number(value) >= Number(kpi.failBoundary))
  ) {
    return 'warning';
  }

  return 'danger';
}

function getFindingsTheme(kpi: KPI, major: number, critical: number) {
  if (major === 0 && critical === 0) {
    return 'success';
  }

  if (critical === 0 && major > 0 && major < 3) {
    return 'warning';
  }

  return 'danger';
}

function getFindingsCircle(kpi: KPI, quarter: Quarter) {
  const major = quarter?.majorFindings;
  const critical = quarter?.criticalFindings;
  const disabledMajor = critical === null || critical === '';
  const disabledCritical = critical === null || critical === '';
  const disabled = disabledMajor && disabledCritical;

  return (
    <li key={kpi.id}>
      <CircleStat
        id={kpi.id}
        percentage={100}
        theme={
          disabled
            ? 'secondary'
            : getFindingsTheme(kpi, parseInt(major, 10), parseInt(critical, 10))
        }
        findings={{ major, critical }}
      >
        <p className="kpi-title">{kpi.title}</p>
      </CircleStat>
    </li>
  );
}

function getKPICicle(kpi: KPI, quarter: Quarter) {
  const value = kpi.identifier && quarter ? quarter[kpi.identifier] : null;
  const disabled = value === null || value === '';

  return (
    <li key={kpi.id}>
      <CircleStat
        id={kpi.id}
        percentage={value}
        theme={disabled ? 'secondary' : getTheme(value, kpi)}
        unit={kpi.unit !== undefined ? ` ${kpi.unit}${value !== '1' && 's'}` : '%'}
      >
        <p className="kpi-title">{kpi.title}</p>
      </CircleStat>
    </li>
  );
}

function KPICircles({ KPIs, quarter }: Props) {
  //Specific order that is different to graphs due to offset
  const order = [4, 5, 10, 8, 9, 6, 11, 1, 2, 3];
  const ordered = KPIs.length > 0 ? order.map((x) => KPIs.find((f) => x === f.id)) : [];
  return (
    <>
      {ordered
        ?.filter((kpi) => kpi && !kpi.hide)
        .map((kpi) =>
          kpi.identifier !== 'findings'
            ? getKPICicle(kpi, quarter)
            : getFindingsCircle(kpi, quarter),
        )}
    </>
  );
}

export default KPICircles;
